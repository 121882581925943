<template>
    <div class="page">
        <!-- <button @click="$router.push({name:'page1'})">page1</button>
        <button @click="$router.push({name:'page2'})">page2</button>
        <button @click="$router.push({name:'page3'})">page3</button>
        <button @click="$router.push({name:'page4'})">page4</button> -->
        <div class="titleContainer">
            <div class="zh">巨烨健康管理云平台</div>
            <div class="en">ZAYATA HEALTH MANAGEMENT SAAS PLATFORM</div>
        </div>

        <div class="cardContainer">
            <!-- @mouseleave="card1=false" @mouseenter="card1=true" -->
            <div class="card" @click="$router.push({name:'page1'})">
                <!-- <dv-decoration-7 v-if="card1">设备流向数据大屏（世界版）</dv-decoration-7> -->
                <div>设备流向数据大屏（世界版）</div>
            </div>
            <!-- @mouseleave="card2=false" @mouseenter="card2=true" -->
            <div class="card" @click="$router.push({name:'page2'})">
                <!-- <dv-decoration-7 v-if="card2"> 设备流向数据大屏（中国版）</dv-decoration-7> -->
                <div>设备流向数据大屏（中国版）</div>
            </div>
            <!-- @mouseleave="card3=false" @mouseenter="card3=true" -->
            <div class="card" @click="$router.push({name:'page4'})">
                <!-- <dv-decoration-7 v-if="card3">药品信息化追溯区块链大屏</dv-decoration-7> -->
                <div>药品信息化追溯区块链大屏</div>
            </div>
        </div>
        <div class="cardContainer">
            <!-- @mouseleave="card4=false" @mouseenter="card4=true" -->
            <div class="card" @click="$router.push({name:'page3'})">
                <!-- <dv-decoration-7 v-if="card4">精神疾病用药管理治安大屏</dv-decoration-7> -->
                <div>精神疾病用药管理治安大屏</div>
            </div>
            <!-- @click="$router.push({name:'page5'})" -->
            <!-- @mouseleave="card5=false" @mouseenter="card5=true" -->
            <div class="card" @click="$router.push({name:'page5'})">
                <!-- <dv-decoration-7 v-if="card5">设备生态链信息数据驾驶舱</dv-decoration-7> -->
                <div>设备生态链信息数据驾驶舱</div>
            </div>
            <!-- @click="$router.push({name:'page6'})" -->
            <!-- @mouseleave="card6=false" @mouseenter="card6=true" -->
            <div class="card" @click="$router.push({name:'page6'})">
                <!-- <dv-decoration-7 v-if="card6">连锁药房企业客户用药管理大屏</dv-decoration-7> -->
                <div>连锁药房企业客户用药管理大屏</div>
            </div>
        </div>


    </div>
</template>
<script>
    let timerInterval
    let time //循环周期
    let actPage = 1
    export default {
        data() {
            return {
                card1: false,
                card2: false,
                card3: false,
                card4: false,
                card5: false,
                card6: false,
            }
        },
        mounted() {
            if (this.$store.state.int) return
            this.$store.state.int = true
            if (!this.$route.query.time) return
            time = parseInt(this.$route.query.time)
            this.$store.commit("SET_lbTime", time)
            this.$router.push({ name: `page${actPage}` })
            this.start()
            document.onkeydown = e => {
                let e1 = e || window.event
                let key = e1.keyCode
                if (key != 13) return
                if (timerInterval) {
                    clearInterval(timerInterval)
                    timerInterval = null
                } else {
                    this.start()
                }
            }
        },
        methods: {
            start() {
                timerInterval = setInterval(() => {
                    let lbTime = this.$store.state.lbTime - 1
                    if (lbTime <= 0) {
                        lbTime = time
                        actPage = actPage >= 6 ? 1 : actPage + 1
                        this.$router.push({ name: `page${actPage}` })
                    }
                    this.$store.commit("SET_lbTime", lbTime)
                }, 1000);
            }
        }
    }
</script>
<style scoped lang="less">
    .page {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-image: url('../assets/image/homeBG.png');
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .titleContainer {
        text-align: center;

        .zh {
            font-size: 1rem;
            font-weight: 800;
            color: #FFFFFF;
        }

        .en {
            font-size: .2rem;
            font-weight: 400;
            color: #FFFFFF;
            letter-spacing: 0.1rem;
            word-spacing: .1rem;
        }
    }

    .cardContainer {
        display: flex;
        justify-content: space-between;
        margin: 0 1.48rem;
        margin-top: .65rem;

        &:nth-child(2) {
            margin-top: 1.5rem;

            .card {
                &:nth-child(1) {
                    background-image: url('../assets/image/1.png');
                    padding-left: 1.16rem;
                }

                &:nth-child(2) {
                    background-image: url('../assets/image/2.png');
                    padding-left: 1.16rem;
                }

                &:nth-child(3) {
                    background-image: url('../assets/image/3.png');
                    padding-left: 1.26rem;
                }
            }

        }

        .card {
            width: 5.15rem;
            height: 1.2rem;
            background-image: url('../assets/image/4.png');
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            padding-left: .44rem;
            box-sizing: border-box;
            padding-top: .15rem;
            font-size: .26rem;
            font-weight: 800;
            color: #FFFFFF;
            cursor: pointer;

            &:nth-child(1) {
                padding-left: 1.3rem;
            }

            &:nth-child(2) {
                background-image: url('../assets/image/5.png');
                padding-left: 1.3rem;

            }

            &:nth-child(3) {
                background-image: url('../assets/image/6.png');
                padding-left: 1.1rem
            }
        }
    }
</style>